<template>
  <div>
    <event-history-filters
      :event-hub="eventHub"
      :types="types"
    />
    <b-row v-if="canExportEvents()">
      <b-col class="text-right px-0">
        <b-button
          variant="outline-success"
          class="m-1"
          :href="downloadExcelUrl"
        >
          Download as Excel
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-table
            :fields="tableFields"
            :items="parcels"
            :busy="loading"
            show-empty
            responsive
            empty-text="No matching records found"
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template
              v-if="companyList.length > 1"
              #cell(logo)="data"
            >
              <CompanyThumb
                :logo="data.item.company.logo"
                :name="data.item.company.name"
              />
            </template>

            <template #cell(name)="data">
              <router-link :to="data.item.link">
                <strong :class="data.item.class">{{ data.item.name }}</strong>
              </router-link>
            </template>

            <template #cell(type)="data">
              <b-badge
                v-if="statusMappings[data.item.type]"
                pill
                variant="primary"
              >
                {{ statusMappings[data.item.type] }}
              </b-badge>
              <b-badge
                v-if="!statusMappings[data.item.type]"
                pill
                variant="light-primary"
              >
                {{ data.item.type }}
              </b-badge>
            </template>

            <template #cell(at)="data">
              <strong :class="data.item.class">{{ data.item.at | formatDate }}</strong>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <!-- View log button -->
              <b-button
                v-b-modal.callback-log-modal
                :variant="callbacksModalVariant(data.item.callback_logs)"
                size="sm"
                :disabled="!data.item.callback_logs.length"
                @click="selectedLogs = data.item.callback_logs"
              >
                Callbacks
              </b-button>
            </template>

          </b-table>
          <pagination
            :event-hub="eventHub"
            :per-page="30"
          />
        </b-card>
      </b-col>
    </b-row>
    <callback-log-modal
      v-if="selectedLogs.length"
      :logs="selectedLogs"
    />
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BBadge,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import Vue from 'vue'
import EventHistoryFilters from '@/views/shipments/cards/EventHistoryFilters.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import handleError from '@/views/components/errorHandler'
import CallbackLogModal from '@/views/callbacks/modals/CallbackLogModal.vue'
import CompanyThumb from '@/views/components/CompanyThumb.vue'

export default {
  components: {
    BSpinner,
    CompanyThumb,
    CallbackLogModal,
    Pagination,
    EventHistoryFilters,
    BTable,
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
  },
  data() {
    return {
      eventHub: new Vue(),
      loading: false,
      selectedLogs: [],
      types: [],
      page: 0,
      perPage: 30,
      sortBy: 'created_at',
      isSortDirDesc: true,
      parcels: [],
      filters: {},
    }
  },
  computed: {
    companyList() {
      return this.$profile().data.user.companies
    },
    tableFields() {
      return [
        ...[this.companyList.length > 1 ? { key: 'logo', label: 'Company', sortable: false } : null],
        'document_number',
        'tracking_number',
        'type',
        'at',
        'actions',
      ]
    },
    statusMappings() {
      const types = []
      this.types.forEach(item => {
        types[item.value] = item.label
      })
      return types
    },
    downloadExcelUrl() {
      return this.searchQuery('/excel', true)
    },
  },
  created() {
    this.eventHub.$on('updateFilters', this.handleUpdateFilters)
    this.eventHub.$on('pageChange', this.handlePageChange)
    this.fetchStatuses()
  },
  beforeDestroy() {
    this.eventHub.$off('updateFilters', this.handleUpdateFilters)
    this.eventHub.$off('pageChange', this.handlePageChange)
  },
  methods: {
    handleUpdateFilters(data) {
      this.filters = data
      this.fetchData()
    },
    handlePageChange(newPage) {
      this.page = newPage
      this.fetchData()
    },
    searchQuery(urlAppendix = '', removeCompany = false) {
      const baseUrl = `${process.env.VUE_APP_API_URL}/v1/api`
      const companySegment = removeCompany ? '' : `/companies/${this.$activeCompany().data.company.id}`
      const url = `${baseUrl}${companySegment}/events${urlAppendix}`

      const queryParams = new URLSearchParams({
        page: this.$data.page,
        per_page: this.$data.perPage,
        sortBy: this.$data.sortBy,
        sortDesc: this.$data.isSortDirDesc,
      })

      Object.entries(this.$data.filters).forEach(([key, value]) => {
        if (value !== null) queryParams.append(key, value)
      })

      return `${url}?${queryParams.toString()}`
    },
    hasSuccessfulLogs(logs) {
      let found = false
      logs.forEach(log => {
        if (log.status === 'succeeded') {
          found = true
        }
      })

      return found
    },
    fetchStatuses() {
      this.$http.get('/v1/api/events/statuses')
        .then(response => {
          this.types = response.data.data
        })
    },
    fetchData() {
      this.loading = true
      this.$http.get(this.searchQuery())
        .then(response => {
          this.$data.parcels = response.data.data
          this.$data.eventHub.$emit('updateMeta', response.data.meta)
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
        .finally(() => {
          this.loading = false
        })
    },
    callbacksModalVariant(logs) {
      let variant = ''

      if (logs.length) {
        if (!this.hasSuccessfulLogs(logs)) {
          variant = 'danger'
        }

        if (this.hasSuccessfulLogs(logs)) {
          variant = 'success'
        }
      }

      return variant
    },
    canExportEvents() {
      return this.$activeCompany().data.company.config?.canTemuEventsExport
    },
  },
}
</script>

<style scoped>

</style>
