<template>

  <b-row>
    <b-col>
      <b-card title="Filters">
        <b-form @keyup.enter="filterData">
          <b-row>
            <b-col>
              <b-form-group
                label="Type"
                label-for="type"
              >
                <v-select
                  v-model="selectedFilterType"
                  :options="typeOptions"
                  :multiple="true"
                  :clearable="false"
                  input-id="type"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Date range"
                label-for="date-range"
              >
                <flat-pickr
                  id="date-range"
                  v-model="filters.dateRange"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Company name filter -->
            <b-col v-if="companyList.length > 1">
              <b-form-group
                label="Company"
                label-for="company"
              >
                <v-select
                  id="company"
                  v-model="filters.companyIds"
                  :reduce="option => option.value"
                  label="label"
                  multiple
                  :options="suggestionList"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Document number"
                label-for="document-number"
                class="full-width"
              >
                <b-input-group>
                  <b-form-tags
                    id="document-number"
                    v-model="filters.document_number"
                    label="Document number"
                    :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
                    separator=" ,;"
                    placeholder="Enter document numbers separated by space, comma or semicolon"
                    no-add-on-enter
                    add-on-change
                    clearable
                  />
                  <b-input-group-append v-if="filters.document_number.length">
                    <b-button
                      id="filter-reset"
                      variant="secondary"
                      size="sm"
                      @click="filters.document_number = []"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Container code"
                label-for="container-code"
                class="full-width"
              >
                <b-input-group>
                  <b-form-tags
                    id="container-code"
                    v-model="filters.container_code"
                    label="Container code"
                    :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
                    separator=" ,;"
                    placeholder="Enter container codes separated by space, comma or semicolon"
                    no-add-on-enter
                    add-on-change
                    clearable
                  />
                  <b-input-group-append v-if="filters.container_code.length">
                    <b-button
                      id="filter-reset"
                      variant="secondary"
                      size="sm"
                      @click="filters.container_code = []"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between">
                <b-form-group
                  label="Parcel tracking number"
                  label-for="parcel-tracking-number"
                  class="full-width"
                >
                  <b-input-group>
                    <b-form-tags
                      id="parcel-tracking-number"
                      v-model="filters.parcel_tracking_number"
                      label="Parcel tracking number"
                      :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
                      separator=" ,;"
                      placeholder="Enter tracking codes separated by space, comma or semicolon"
                      no-add-on-enter
                      add-on-change
                      clearable
                    />
                    <b-input-group-append v-if="filters.parcel_tracking_number.length">
                      <b-button
                        id="filter-reset"
                        variant="secondary"
                        size="sm"
                        @click="filters.parcel_tracking_number = []"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <div class="ml-2">
                  <b-button
                    id="apply-filters-btn"
                    class="mt-2"
                    type="button"
                    variant="primary"
                    @click="filterData()"
                  >
                    Filter
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BButton,
  BFormTags,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import { getSelectedCompanies, parseFilters, updateSelectedCompanies } from '@core/utils/filter'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    flatPickr,
    BCard,
    vSelect,
  },
  props: ['eventHub', 'types'],
  data() {
    return {
      filters: {
        document_number: [],
        container_code: [],
        parcel_tracking_number: [],
        type: '',
        dateRange: this.getLastThreeDays(),
        companyIds: getSelectedCompanies(),
      },
      companyList: [],
      selectedFilterType: null,
    }
  },
  computed: {
    suggestionList() {
      const filtered = []
      this.companyList.forEach(item => {
        filtered.push({
          label: item.name,
          value: item.id,
        })
      })

      return filtered
    },
    typeOptions() {
      const types = []
      types.push({
        label: 'N/A',
        value: '',
      })
      this.types.forEach(item => {
        types.push(item)
      })
      return types
    },
    formattedValues() {
      const result = []
      this.selectedFilterType.forEach(item => {
        result.push(item.value)
      })
      return result
    },
  },
  created() {
    this.$data.companyList = this.$profile().getUserAndAuthorizedCompanies()
    this.filterData()
  },
  methods: {
    filterData() {
      if (this.selectedFilterType) {
        this.filters.type = this.formattedValues
      }
      const filters = { ...this.filters }
      filters.document_number = this.filters.document_number.join(',')
      filters.container_code = this.filters.container_code.join(',')
      filters.parcel_tracking_number = this.filters.parcel_tracking_number.join(',')
      this.eventHub.$emit('updateFilters', parseFilters(filters))
      updateSelectedCompanies(this.filters.companyIds)
    },
    getLastThreeDays() {
      const today = new Date()
      const threeDaysAgo = new Date()
      threeDaysAgo.setDate(today.getDate() - 3)
      const formatDate = date => date.toISOString().split('T')[0]
      return `${formatDate(threeDaysAgo)} to ${formatDate(today)}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#apply-filters-btn {
  padding-top: .63rem;
  padding-bottom: .63rem;
}
</style>
